import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import TopBar from "../partials/TopBar"
import { Grid } from "@material-ui/core"
import * as analyticsActions from "../../actions/analyticsActions"
import DashboardCard from "../partials/DashboardCard"
import ReactVis from "../partials/ReactVis"

const Dashboard = () => {
  const dispatch = useDispatch()
  const auth = useSelector((state) => ({ ...state.auth }))
  const analytics = useSelector((state) => ({ ...state.analytics }))

  useEffect(() => {
    dispatch(analyticsActions.getReservationAnalytics({ token: auth.token }, "WEEK"))
  }, [])

  return (
    <React.Fragment>
      <TopBar />
      <Grid container direction="row" justify="center" alignItems="center" spacing={2} style={{ marginTop: 20 }}>
        <Grid item md={3} xs={12}>
          <DashboardCard value={analytics.allOpenReservations} title={"Open Reservations"} color="yellow" />
        </Grid>
        <Grid item md={3} xs={12}>
          <DashboardCard value={analytics.allOpenRentals} title={"Open Rentals"} color="blue" />
        </Grid>
        <Grid item md={3} xs={12}>
          <DashboardCard value={analytics.allOverdueRentals} title={"Overdue Rentals"} color="purple" />
        </Grid>
      </Grid>
      <Grid container direction="row" justify="center" alignItems="center" style={{ marginTop: 50 }}>
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <h2>All Open Reservations</h2>
        </Grid>
        <ReactVis />
      </Grid>
      {/*Reservations Created by day
       <ul>
        {analytics.reservationsCreated.map((value, index) => (
          <li key={index}>
            {value.date} {value.value}
          </li>
        ))}
      </ul>
      Reservations By Agent
      <ul>
        {analytics.reservationsPerAgent.map((value, index) => (
          <li key={index}>
            {value.date}
            {analytics.reservationsPerAgent[index].value.map((innerValue, innerIndex) => (
              <p key={innerIndex}>
                {innerValue.value} {innerValue.agent}
              </p>
            ))}
          </li>
        ))}
      </ul>
      Rentals Per Status
      <ul>
        {analytics.rentalsStartPerStatus.map((value, index) => (
          <li key={index}>
            {value.date}{" "}
            {analytics.rentalsStartPerStatus[index].value.map((innerValue, innerIndex) => (
              <p key={innerIndex}>
                {innerValue.value} {innerValue.status}
              </p>
            ))}
          </li>
        ))}
      </ul> */}
    </React.Fragment>
  )
}

export default Dashboard
