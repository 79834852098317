import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Grid, Typography } from "@material-ui/core"
import { useDispatch, useSelector } from "react-redux"
import * as bookingsActions from "../../actions/bookingsActions"

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles((theme) => ({
  root: {
    "& label.MuiFormLabel-root": {
      backgroundColor: "#fff",
      marginLeft: "-5px",
      padding: "0 6px",
    },
  },
}))

const BookingConfirmation = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const bookings = useSelector((state) => ({ ...state.bookings }))
  const auth = useSelector((state) => ({ ...state.auth }))
  // const locations = useSelector(state => ({ ...state.locations }));
  const userInfo = useSelector((state) => ({ ...state.userInfo }))
  // const [emailRecipient, setEmailRecipient] = React.useState({
  //   Hertz: false,
  //   Insurer: false,
  //   Customer: true
  // });

  // const emails = ["hertz@mail.com", "insurer@mail.com", "customer@mail.com"];

  // const handleChange = name => event => {
  //   setEmailRecipient({ ...emailRecipient, [name]: event.target.checked });
  // };

  // function getLocationName(lCode) {
  //   const location = locations.locations ? locations.locations.filter(l => l.locationCode.includes(lCode)) : null;
  //   return location ? location[0].locationName : "";
  // }

  // eslint-disable-next-line no-unused-vars
  const handleOnSend = (e) => {
    dispatch(
      bookingsActions.downloadConfirmationEmail({
        type: "createReservation",
        mode: "reservation",
        token: auth.token,
        reservationNumber: bookings.reservationNumber,
        rentalNumber: "",
        userInfo: userInfo,
      })
    )
  }

  return (
    <Grid container spacing={0} justify="center" className={classes.root}>
      <Grid container item xs={12} spacing={1}>
        <Typography variant="h6">Confirmation Spiel</Typography>
        <br />
        <ol style={{ alignText: "justify" }}>
          <li>
            We have booked you a vehicle for (advise days) which will be available at (advise location) at (advise date
            and time).
          </li>
          <li>
            The booking is for the specified time period. If you require the vehicle for longer than this, you will need
            to return the hire vehicle and commence a new rental at your own cost.
          </li>
          <li>
            When picking up the rental vehicle you are required to leave a $1 credit card bond. The cost of all fuel,
            tolls and other ancillary items are the responsibility of the renter.
          </li>
          <li>
            The hire vehicle will be supplied with a full tank of fuel. We suggest you return the hire vehicle with a
            full tank, because if the vehicle does not have a full tank, the cost will come out of your bond at a price
            determined by Hertz.
          </li>
          <li>
            Under the agreement with Hertz, we provide the insurance coverage for the rental vehicle. Should you have an
            accident you are required to contact us directly to lodge a claim on your policy. Your applicable excess
            will apply unless you purchase an optional insurance as follows:
          </li>
          <ul>
            <li>
              Accident Excess is $650 (including GST) or, you may choose to purchase the following Accident Excess
              reduction (AER) from the vehicle hire company to reduce the minimum Accident Access:
            </li>
            <li>
              Passenger Vehicle – Accident Excess Reduction (AER) at $6.60 (including GST) per day reducing the excess
              to $100; or
            </li>
            <li>Passenger Vehicle – Maximum Cover at $9.90 (including GST) per day reducing the excess to $0.</li>
            <li>Higher excesses apply on Prestige, 4WD and Commercial vehicles</li>
          </ul>
          <li>
            When you pick up the vehicle, make sure to check over the vehicle thoroughly to ensure there is no damage on
            the hire vehicle. If there is damage, please notify the Hertz representative before taking the vehicle and
            ensure it is recorded. If this is not done, you may be liable for the costs to repair the pre-existing
            damage.
          </li>
        </ol>
      </Grid>
    </Grid>
  )
}

export default BookingConfirmation
