/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React, { Fragment, useEffect } from "react"
import MaterialTable from "material-table"
import tableIcons from "../../utils/helper"

import {
  Card,
  Grid,
  Button,
  makeStyles,
  Typography,
  Popover
} from "@material-ui/core"

import { useDispatch } from "react-redux"
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos"
import HelpOutlineIcon from "@material-ui/icons/HelpOutline"
import Paper from "@material-ui/core/Paper"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"

import * as userGroupActions from "../../actions/userGroupActions"

import _ from "lodash"

const UserGroupSummary = props => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const [selectedTab, setSelectedTab] = React.useState(0)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [cols, setCols] = React.useState(
    props.itGroups && selectedTab == 0
      ? [
          { title: "Name", field: "name" },
          { title: "_id", field: "_id" },
        ]
      : props.locationGroups && selectedTab == 1
      ? [
          { title: "Name", field: "name" },
          { title: "_id", field: "_id" },
        ]
      : props.vehicleGroups && selectedTab == 2
      ? [
          { title: "Name", field: "name" },
          { title: "_id", field: "_id" },
        ]
      : []
  )

  let checkedItGroups = []
  let checkedLocationGroups = []
  let checkedVehicleGroups = []

  const handlePopoverOpen = event => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue)
  }

  useEffect(() => {
    setCols(
      selectedTab == 0
        ? [
            { title: "Name", field: "name" },
            { title: "ID", field: "_id", hidden: true }
          ]
        : selectedTab == 1
        ? [
            { title: "Name", field: "name" },
            { title: "ID", field: "_id", hidden: true }
          ]
        : selectedTab == 2
        ? [
            { title: "Name", field: "name" },
            { title: "ID", field: "_id", hidden: true }
          ]
        : []
    )
  }, [selectedTab])

  function setItGroupSelected(rowData) {
    /* for (
      var i = 0;
      i <
      props.users[props.selectedRow.tableData.id].allowedItCodeGroups.length;
      i++
    ) {
      if (
        rowData.name ==
        props.users[props.selectedRow.tableData.id].allowedItCodeGroups[i]
      ) {
        checkedItGroups.push(rowData.id)
        return true
      }
    } */
    const checked = Boolean(props.selectedRow.itCodeGroups.find(e => e._id === rowData._id));
    if(checked) {
      checkedItGroups.push(rowData.id)
    }
    return checked;
  }

  function setLocationSelected(rowData) {
    /* for (
      var i = 0;
      i < props.users[props.selectedRow.tableData.id].allowedLocations.length;
      i++
    ) {
      if (
        rowData.locationCode ==
        props.users[props.selectedRow.tableData.id].allowedLocations[i]
      ) {
        return true
      }
    } */

      const checked = Boolean(props.selectedRow.locationGroups.find(e => e._id === rowData._id));
      if(checked) {
        checkedLocationGroups.push(rowData._id)
      }
      return checked;
  }

  function setVehicleSelected(rowData) {
    /* for (
      var i = 0;
      i <
      props.users[props.selectedRow.tableData.id].allowedVehicleGroups.length;
      i++
    ) {
      if (
        rowData.code ==
        props.users[props.selectedRow.tableData.id].allowedVehicleGroups[i]
      ) {
        return true
      }
    } */

      const checked = Boolean(props.selectedRow.vehicleModelGroups.find(e => e._id === rowData._id))
      if(checked) {
        checkedVehicleGroups.push(rowData._id)
      }
      return checked;
  }

  function setData(data, type) {
    data = props.selectedRow
      ? data.map(rowData =>
          Object.assign(
            {
              ...rowData,
              tableData: {
                checked:
                  type == "itGroups"
                    ? setItGroupSelected(rowData)
                    : type == "locations"
                    ? setLocationSelected(rowData)
                    : type == "vehicles"
                    ? setVehicleSelected(rowData)
                    : []
              }
            },
            rowData
          )
        )
      : []

    data = _.orderBy(data, { tableData: { checked: false } })
    return data
  }

  const itGroupData = props.itGroups ? setData(props.itGroups, "itGroups") : []
  const locationGroupData = props.locationGroups
    ? setData(props.locationGroups, "locations")
    : []
  const vehicleGroupData = props.vehicleGroups ? setData(props.vehicleGroups, "vehicles") : []

  function checkRowItGroups(rowData) {
    checkedItGroups = []
    if (rowData.length == 0) {
      for (let i = 0; i < itGroupData.length; i++) {
        itGroupData[i].tableData.checked = false
      }
    } else {
      for (let i = 0; i < itGroupData.length; i++) {
        for (let j = 0; j < rowData.length; j++) {
          if (itGroupData[i]._id == rowData[j]._id) {
            itGroupData[i].tableData.checked = rowData[j].tableData.checked
            if (rowData[j].tableData.checked == true) {
              checkedItGroups.push(rowData[j]._id)
            }
          }
        }
      }
    }
  }

  function checkRowLocations(rowData) {
    checkedLocationGroups = []
    if (rowData.length == 0) {
      for (let i = 0; i < locationGroupData.length; i++) {
        locationGroupData[i].tableData.checked = false
      }
    } else {
      for (let i = 0; i < locationGroupData.length; i++) {
        for (let j = 0; j < rowData.length; j++) {
          if (locationGroupData[i]._id == rowData[j]._id) {
            locationGroupData[i].tableData.checked = rowData[j].tableData.checked
            if (rowData[j].tableData.checked == true) {
              checkedLocationGroups.push(rowData[j]._id)
            }
          }
        }
      }
    }
  }

  function checkRowVehicles(rowData) {
    checkedVehicleGroups = []
    if (rowData.length == 0) {
      for (let i = 0; i < vehicleGroupData.length; i++) {
        vehicleGroupData[i].tableData.checked = false
      }
    } else {
      for (let i = 0; i < vehicleGroupData.length; i++) {
        for (let j = 0; j < rowData.length; j++) {
          if (vehicleGroupData[i]._id == rowData[j]._id) {
            vehicleGroupData[i].tableData.checked = rowData[j].tableData.checked
            if (rowData[j].tableData.checked == true) {
              checkedVehicleGroups.push(rowData[j]._id)
            }
          }
        }
      }
    }
  }

  function onUpdateUserGroup(value) {
    const itCodeGroups = value === "itCodeGroups" ? checkedItGroups : props.selectedRow.itCodeGroups.map(e => e._id);
    const locationGroups = value === "locationGroups" ? checkedLocationGroups : props.selectedRow.locationGroups.map(e => e._id);
    const vehicleModelGroups = value === "vehicleModelGroups" ? checkedVehicleGroups : props.selectedRow.vehicleModelGroups.map(e => e._id);
    const userGroup = {
      _id: props.selectedRow._id,
      name: props.selectedRow.name,
      active: props.selectedRow.active,
      itCodeGroups,
      locationGroups,
      vehicleModelGroups
    }
    console.log({userGroup})
    dispatch(userGroupActions.setUserGroup({token: props.auth.token}, userGroup))
    /* if (checkedItGroups.length == 0 && value != "itGroups") {
      checkedItGroups = _.cloneDeep(
        props.users[props.selectedRow.tableData.id].allowedItCodeGroups
      )
    }
    if (checkedLocationGroups.length == 0 && value != "locations") {
      checkedLocationGroups = _.cloneDeep(
        props.users[props.selectedRow.tableData.id].allowedLocations
      )
    }
    if (checkedVehicleGroups.length == 0 && value != "vehicles") {
      checkedVehicleGroups = _.cloneDeep(
        props.users[props.selectedRow.tableData.id].allowedVehicleGroups
      )
    }
    dispatch(
      userInfoActions.updateUser(
        { token: auth.token },
        props.users[props.selectedRow.tableData.id].emailAddress,
        props.users[props.selectedRow.tableData.id].userType,
        checkedItGroups,
        checkedLocationGroups,
        checkedVehicleGroups
      )
    ) */
  }

  return (
    <Fragment>
      <Grid item xs={7} lg={5}>
        <Card className={classes.summaryCardActive}>
          {props.selectedRow && (
            <React.Fragment>
              <MaterialTable
                icons={tableIcons}
                title={
                  selectedTab == 0
                    ? "IT Groups"
                    : selectedTab == 1
                    ? "Location Groups"
                    : selectedTab == 2
                    ? "Vehicle Groups"
                    : ""
                }
                columns={cols}
                options={{
                  minBodyHeight: "calc(100vh - 350px)",
                  maxBodyHeight: "calc(100vh - 350px)",
                  pageSize: 10,
                  selection: true,
                  selectionProps: rowData => ({
                    disabled: rowData.active == false
                  })
                }}
                style={{ boxShadow: "none" }}
                data={
                  selectedTab == 0
                    ? itGroupData.map(x => Object.assign({}, x))
                    : selectedTab == 1
                    ? locationGroupData.map(x => Object.assign({}, x))
                    : selectedTab == 2
                    ? vehicleGroupData.map(x => Object.assign({}, x))
                    : []
                }
                onSelectionChange={rowData => {
                  selectedTab == 0
                    ? checkRowItGroups(rowData)
                    : selectedTab == 1
                    ? checkRowLocations(rowData)
                    : selectedTab == 2
                    ? checkRowVehicles(rowData)
                    : ""
                }}
              />
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
                style={{ marginTop: 15 }}
              >
                <Popover
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right"
                  }}
                  transformOrigin={{
                    vertical: "bottom",
                    horizontal: "left"
                  }}
                  id="mouse-over-popover"
                  className={classes.popover}
                  classes={{
                    paper: classes.paper
                  }}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handlePopoverClose}
                  disableRestoreFocus
                  style={{ border: "none" }}
                >
                  {selectedTab == 0 && (
                    <Typography>
                      Select IT Groups to be assigned to the user and finalise
                      the process by pressing &quot;UPDATE IT GROUPS&quot;
                    </Typography>
                  )}
                  {selectedTab == 1 && (
                    <Typography>
                      By default, all locations will be be accessible by the
                      user.
                      <br />
                      Assigning Locations will limit the user to only being able
                      to use those selected.
                    </Typography>
                  )}
                  {selectedTab == 2 && (
                    <Typography>
                      By default, all Vehicles will be be accessible by the
                      user.
                      <br />
                      Assigning Vehicles will limit the user to only being able
                      to use those selected.
                    </Typography>
                  )}
                </Popover>
                <HelpOutlineIcon
                  onMouseEnter={handlePopoverOpen}
                  onMouseLeave={handlePopoverClose}
                  style={{ marginLeft: 15 }}
                ></HelpOutlineIcon>
                <Grid
                  item
                  xs={6}
                  className={classes.actionButtons}
                  style={{ textAlign: "right", marginRight: 10 }}
                >
                  {props.selectedRow && selectedTab == 0 && (
                    <Button
                      color="primary"
                      onClick={e => onUpdateUserGroup("itCodeGroups", e)}
                    >
                      Update IT Groups
                      <ArrowForwardIosIcon
                        style={{
                          marginLeft: 5,
                          fontSize: 20,
                          marginBottom: 2
                        }}
                      ></ArrowForwardIosIcon>
                    </Button>
                  )}
                  {props.selectedRow && selectedTab == 1 && (
                    <Button
                      color="primary"
                      onClick={e => onUpdateUserGroup("locationGroups", e)}
                    >
                      Update Location Groups
                      <ArrowForwardIosIcon
                        style={{
                          marginLeft: 5,
                          fontSize: 20,
                          marginBottom: 2
                        }}
                      ></ArrowForwardIosIcon>
                    </Button>
                  )}
                  {props.selectedRow && selectedTab == 2 && (
                    <Button
                      color="primary"
                      onClick={e => onUpdateUserGroup("vehicleModelGroups", e)}
                    >
                      Update Vehicle Groups
                      <ArrowForwardIosIcon
                        style={{
                          marginLeft: 5,
                          fontSize: 20,
                          marginBottom: 2
                        }}
                      ></ArrowForwardIosIcon>
                    </Button>
                  )}
                </Grid>
              </Grid>
            </React.Fragment>
          )}
        </Card>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          style={{ marginTop: 10 }}
        >
          <Card style={{ width: "100%", height: "100%" }}>
            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="flex-start"
              className={classes.actionButtons}
            >
              <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
              >
                <Paper style={{ minHeight: 51, width: "100%", paddingTop: 3 }}>
                  {props.selectedRow && (
                    <Tabs
                      value={selectedTab}
                      onChange={handleTabChange}
                      indicatorColor="primary"
                      textColor="primary"
                      aria-label="disabled tabs example"
                      variant="fullWidth"
                    >
                      <Tab label="IT GROUPS" />
                      <Tab label="LOCATIONS" />
                      <Tab label="VEHICLES" />
                    </Tabs>
                  )}
                </Paper>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </Fragment>
  )
}

const cardWidget = {
  textAlign: "center",
  height: "100%",
  width: "100%",
  padding: "20px 0 10px 0",
  position: "relative",
  overflow: "hidden"
}

const useStyles = makeStyles(theme => ({
  root: {
    "& button.MuiButtonBase-root": {
      padding: "5px"
    }
  },
  actionButtons: {
    height: "100%",
    textAlign: "center"
  },
  summaryCard: {
    height: "calc(100vh - 106px)"
  },
  summaryCardActive: {
    height: "calc(100vh - 168px)"
  },
  vehicleCard: cardWidget,
  progress: {
    margin: theme.spacing(2)
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  },
  customerDetails: {
    "& input": {
      fontSize: "14px",
      color: "#000",
      padding: "5px 0"
    },
    "& label.Mui-disabled": {
      fontSize: "13px",
      color: "#B9BBBE"
    }
  },
  infoCard: {
    ...cardWidget,
    padding: "10px 0 10px 0",
    "& .MuiCardContent-root": {
      paddingTop: "0",
      paddingBottom: "0"
    },
    "& .MuiTextField-root": {
      margin: "0px"
    },
    "& input": {
      fontSize: "14px",
      color: "#000",
      padding: "5px 0"
    },
    "& .MuiInputBase-root": {
      marginTop: "12px"
    },
    "& label.Mui-disabled": {
      fontSize: "13px",
      color: "#B9BBBE"
    }
  },
  popover: {
    pointerEvents: "none"
  }
}))

export default UserGroupSummary
