/* eslint-disable react/prop-types */
import React from "react"
import Box from "@material-ui/core/Box"
import { makeStyles } from "@material-ui/core/styles"
import { HERTZ_YELLOW } from "../../constants/style"

const useStyles = makeStyles({
  root: {
    margin: 10,
    padding: 10,
    borderRadius: 5,
    textAlign: "center",
  },
  blue: {
    border: `2px solid ${HERTZ_YELLOW}`,
  },
  yellow: {
    border: `2px solid ${HERTZ_YELLOW}`,
  },
  purple: {
    border: `2px solid ${HERTZ_YELLOW}`,
  },
})

const DashboardCard = (props) => {
  const classes = useStyles(props)
  let style = `${classes.root} ${classes[props.color]}`
  return (
    <Box className={style}>
      <h2 style={{ fontSize: 34, margin: 10 }}>{props.value}</h2>
      <h3>{props.title}</h3>
    </Box>
  )
}

export default DashboardCard
