import * as types from "../constants/actionTypes";
import initialState from "./initialState";

export default function locationGroupReducer(state = initialState.locationGroups, action) {
    switch (action.type) {
        case types.LOCATION_GROUPS.GET.REQUEST:
            return {
                ...state,
                isFetching: true,
            }
        case types.LOCATION_GROUPS.GET.SUCCESS:
            return {
                ...state,
                data: action.result.data.GetLocationGroups,
                isFetching: false,
            }
        case types.LOCATION_GROUPS.GET.FAILURE:
            return {
                ...state,
                data: null,
                isFetching: false,
            }
        case types.LOCATION_GROUPS.DELETE.REQUEST:
            return {
                ...state,
                isFetching: true,
            }
        case types.LOCATION_GROUPS.DELETE.SUCCESS:
            return {
                ...state,
                data: state.data.filter(e => e._id !== action.result._id),
                isFetching: false,
            }
        case types.LOCATION_GROUPS.DELETE.FAILURE:
            return {
                ...state,
                isFetching: false,
            }
        case types.LOCATION_GROUPS.CLEAR:
            return {
                ...state,
                data: null,
                isFetching: false,
            }
        case types.LOCATION_GROUPS.CREATE.REQUEST:
            return {
                ...state,
                isFetching: true,
            }
        case types.LOCATION_GROUPS.CREATE.SUCCESS:
            return {
                ...state,
                data: state.data.concat(action.result.data.CreateLocationGroups),
                isFetching: false,
            }
        case types.LOCATION_GROUPS.CREATE.FAILURE:
            return {
                ...state,
                isFetching: false,
            }
        case types.LOCATION_GROUPS.SET.REQUEST:
            return {
                ...state,
                isFetching: true,
            }
        case types.LOCATION_GROUPS.SET.SUCCESS:
            return {
                ...state,
                data: state.data.map(e => e._id === action.result.data.UpdateLocationGroups._id 
                    ? action.result.data.UpdateLocationGroups : e),
                isFetching: false,
            }
        case types.LOCATION_GROUPS.SET.FAILURE:
            return {
                ...state,
                data: null,
                isFetching: false,
            }
        default:
            return state
    }
}