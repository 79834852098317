// export const AUTH_SERVER_URL = 'https://agatho-prod.hertz.asiapac.io/auth';
// export const API_SERVER_URL = 'https://agatho-prod.hertz.asiapac.io/api';

export const AUTH_SERVER_URL =
  process.env.ENDPOINT === "prod"
    ? "https://agatho-supergraph-prod.hertz.asiapac.io/auth"
    : "https://agatho-dev.hertz.asiapac.io/auth";
export const API_SERVER_URL =
  process.env.ENDPOINT === "prod"
    ? "https://agatho-supergraph-prod.hertz.asiapac.io/api"
    : "https://agatho-dev.hertz.asiapac.io/api";

//replace with api url when finished
export const STATIC_JSON = "https://p6kgn84ca2.execute-api.ap-southeast-2.amazonaws.com/dev/auth";
