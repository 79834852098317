const createType = (type, v = 2) => {
  const schema1 = `{
	    "GET":{
	        "REQUEST": "GET_${type}_REQUEST",
	        "SUCCESS": "GET_${type}_SUCCESS",
	        "FAILURE": "GET_${type}_FAILURE"
	    },
	    "SET":{
	        "REQUEST": "SET_${type}_REQUEST",
	        "SUCCESS": "SET_${type}_SUCCESS",
	        "FAILURE": "SET_${type}_FAILURE"
		},
		"CREATE":{
			"REQUEST": "CREATE_${type}_REQUEST",
	        "SUCCESS": "CREATE_${type}_SUCCESS",
	        "FAILURE": "CREATE_${type}_FAILURE"
		},
		"DELETE":{
			"REQUEST": "DELETE_${type}_REQUEST",
	        "SUCCESS": "DELETE_${type}_SUCCESS",
	        "FAILURE": "DELETE_${type}_FAILURE"
		},
		"DISABLE":{
			"REQUEST": "DISABLE_${type}_REQUEST",
	        "SUCCESS": "DISABLE_${type}_SUCCESS",
	        "FAILURE": "DISABLE_${type}_FAILURE"
		},
		"SEQUENCE":{
			"DELETE":{
				"REQUEST": "SEQUENCE_DELETE_${type}_REQUEST",
				"REQUEST": "SEQUENCE_DELETE_${type}_SUCCESS",
				"REQUEST": "SEQUENCE_DELETE_${type}_FAILURE"
			}
		},
		"EXTEND": {
			"REQUEST": "EXTEND_${type}_REQUEST",
	        "SUCCESS": "EXTEND_${type}_SUCCESS",
	        "FAILURE": "EXTEND_${type}_FAILURE"
		},
		"ESTIMATE": {
			"REQUEST": "ESTIMATE_${type}_REQUEST",
	        "SUCCESS": "ESTIMATE_${type}_SUCCESS",
	        "FAILURE": "ESTIMATE_${type}_FAILURE"
		},
		"MODIFY": {
			"REQUEST": "MODIFY_${type}_REQUEST",
	        "SUCCESS": "MODIFY_${type}_SUCCESS",
	        "FAILURE": "MODIFY_${type}_FAILURE"
		},
		"CLEAR":{
			"REQUEST": "CLEAR_${type}_REQUEST",
			"SUCCESS": "CLEAR_${type}_SUCCESS",
			"FAILURE": "CLEAR_${type}_FAILURE"
		}
	}`

  const schema2 = `{
	    "GET":{
	      "${type}":{
	        "REQUEST": "GET_${type}_REQUEST",
	        "SUCCESS": "GET_${type}_SUCCESS",
	        "FAILURE": "GET_${type}_FAILURE"
	      }
	    },
	    "SET":{
	      "${type}":{
	        "REQUEST": "SET_${type}_REQUEST",
	        "SUCCESS": "SET_${type}_SUCCESS",
	        "FAILURE": "SET_${type}_FAILURE"
	      }
		},
		"CREATE":{
			"REQUEST": "CREATE_${type}_REQUEST",
	        "SUCCESS": "CREATE_${type}_SUCCESS",
	        "FAILURE": "CREATE_${type}_FAILURE"
		},
		"DELETE":{
			"REQUEST": "DELETE_${type}_REQUEST",
	        "SUCCESS": "DELETE_${type}_SUCCESS",
	        "FAILURE": "DELETE_${type}_FAILURE"
		},
		"SEQUENCE":{
			"DELETE":{
				"REQUEST": "SEQUENCE_DELETE_${type}_REQUEST",
				"REQUEST": "SEQUENCE_DELETE_${type}_SUCCESS",
				"REQUEST": "SEQUENCE_DELETE_${type}_FAILURE"
			}
		},
		"EXTEND": {
			"REQUEST": "EXTEND_${type}_REQUEST",
	        "SUCCESS": "EXTEND_${type}_SUCCESS",
	        "FAILURE": "EXTEND_${type}_FAILURE"
		},
		"ESTIMATE": {
			"REQUEST": "ESTIMATE_${type}_REQUEST",
	        "SUCCESS": "ESTIMATE_${type}_SUCCESS",
	        "FAILURE": "ESTIMATE_${type}_FAILURE"
		},
		"MODIFY": {
			"REQUEST": "MODIFY_${type}_REQUEST",
	        "SUCCESS": "MODIFY_${type}_SUCCESS",
	        "FAILURE": "MODIFY_${type}_FAILURE"
		},
	    "CLEAR": "CLEAR_${type}"
	}`

	const schema3 = `{
	    "GET":{
	        "REQUEST": "GET_${type}_REQUEST",
	        "SUCCESS": "GET_${type}_SUCCESS",
	        "FAILURE": "GET_${type}_FAILURE"
	    },
		"PROMOTE":{
	        "REQUEST": "PROMOTE_${type}_REQUEST",
	        "SUCCESS": "PROMOTE_${type}_SUCCESS",
	        "FAILURE": "PROMOTE_${type}_FAILURE"
	    },
		"DEMOTE":{
	        "REQUEST": "PROMOTE_${type}_REQUEST",
	        "SUCCESS": "PROMOTE_${type}_SUCCESS",
	        "FAILURE": "PROMOTE_${type}_FAILURE"
	    },
		"CHANGE_PASSWORD":{
	        "REQUEST": "CHANGE_PASSWORD_${type}_REQUEST",
	        "SUCCESS": "CHANGE_PASSWORD_${type}_SUCCESS",
	        "FAILURE": "CHANGE_PASSWORD_${type}_FAILURE"
	    },
		"REQUIRE_RESET_PASSWORD":{
	        "REQUEST": "REQUIRE_RESET_PASSWORD_${type}_REQUEST",
	        "SUCCESS": "REQUIRE_RESET_PASSWORD_${type}_SUCCESS",
	        "FAILURE": "REQUIRE_RESET_PASSWORD_${type}_FAILURE"
	    },
	    "SET":{
	        "REQUEST": "SET_${type}_REQUEST",
	        "SUCCESS": "SET_${type}_SUCCESS",
	        "FAILURE": "SET_${type}_FAILURE"
		},
		"CREATE":{
			"REQUEST": "CREATE_${type}_REQUEST",
	        "SUCCESS": "CREATE_${type}_SUCCESS",
	        "FAILURE": "CREATE_${type}_FAILURE"
		},
		"DELETE":{
			"REQUEST": "DELETE_${type}_REQUEST",
	        "SUCCESS": "DELETE_${type}_SUCCESS",
	        "FAILURE": "DELETE_${type}_FAILURE"
		},
		"DISABLE":{
			"REQUEST": "DISABLE_${type}_REQUEST",
	        "SUCCESS": "DISABLE_${type}_SUCCESS",
	        "FAILURE": "DISABLE_${type}_FAILURE"
		},
		"SEQUENCE":{
			"DELETE":{
				"REQUEST": "SEQUENCE_DELETE_${type}_REQUEST",
				"REQUEST": "SEQUENCE_DELETE_${type}_SUCCESS",
				"REQUEST": "SEQUENCE_DELETE_${type}_FAILURE"
			}
		},
		"EXTEND": {
			"REQUEST": "EXTEND_${type}_REQUEST",
	        "SUCCESS": "EXTEND_${type}_SUCCESS",
	        "FAILURE": "EXTEND_${type}_FAILURE"
		},
		"ESTIMATE": {
			"REQUEST": "ESTIMATE_${type}_REQUEST",
	        "SUCCESS": "ESTIMATE_${type}_SUCCESS",
	        "FAILURE": "ESTIMATE_${type}_FAILURE"
		},
		"MODIFY": {
			"REQUEST": "MODIFY_${type}_REQUEST",
	        "SUCCESS": "MODIFY_${type}_SUCCESS",
	        "FAILURE": "MODIFY_${type}_FAILURE"
		},
		"CLEAR":{
			"REQUEST": "CLEAR_${type}_REQUEST",
			"SUCCESS": "CLEAR_${type}_SUCCESS",
			"FAILURE": "CLEAR_${type}_FAILURE"
		}
	}`
	const schemas = [
		schema1,
		schema2,
		schema3
	]

  return JSON.parse(schemas[v-1])

  /*
	// - Usage

	let types = createType('RESERVATION')
	types = _.merge(types, createType('ESTIMATE'))
	types = _.merge(types, createType('OPTIONS'))

	export const actions = types

	----
	import * as types from "../constants/actionTypes";

	*/
}

export const USER = createType("USER", 1)
export const CHANGE_PASSWORD = createType("CHANGE_PASSWORD", 1)
export const FORGOT_PASSWORD = createType("FORGOT_PASSWORD", 1)
export const RESET_PASSWORD = createType("RESET_PASSWORD", 1)
export const USERS = createType("USERS", 3)
export const ESTIMATE = createType("ESTIMATE", 1)
export const USER_SETTINGS = createType("USER_SETTINGS", 1)
export const SERVICE_MANAGERS = createType("SERVICE_MANAGERS", 3)
export const GET_SERVICE_SETTINGS = createType("GET_SERVICE_SETTINGS", 1)
export const LOCATIONS = createType("LOCATIONS", 1)
export const LOCATION_GROUPS = createType("LOCATION_GROUPS", 1)
export const VEHICLE_GROUPS = createType("VEHICLE_GROUPS", 1)
export const USER_GROUPS = createType("USER_GROUPS", 1)
export const SEARCHADDRESS = createType("SEARCHADDRESS", 1)
export const RETRIEVEADDRESS = createType("RETRIEVEADDRESS", 1)
export const ALL_LOCATIONS = createType("ALL_LOCATIONS", 1)
export const RESERVATION = createType("RESERVATION", 1)
export const RENTAL = createType("RENTAL", 1)
export const RENTAL_ESTIMATE = createType("RENTAL_ESTIMATE", 1)
export const FAVOURITE_LOCATIONS = createType("FAVOUROTE_LOCATIONS", 1)
export const IT_GROUPS = createType("IT_GROUPS", 1)
export const IT_GROUP = createType("IT_GROUP", 1)
export const IT_CODE = createType("IT_CODE", 1)
export const IT_CODES = createType("IT_CODES", 1)
export const SERVICE_USER = createType("SERVICE_USER", 1)
export const NOTIFICATION = createType("NOTIFICATION", 1)
export const BUSINESS_RULES = createType("BUSINESS_RULES", 1)
export const SERVICE_SETTINGS = createType("SERVICE_SETTINGS", 1)
export const USER_IT_CODE_GROUPS = createType("USER_IT_CODE_GROUPS", 1)
export const USER_LOCATIONS = createType("USER_LOCATIONS", 1)
export const USER_VEHICLES = createType("USER_VEHICLES", 1)
export const USER_PASSWORD = createType("USER_PASSWORD", 1)
export const RESET_REQUIRED = createType("RESET_REQUIRED", 1)
export const ALLOWED_AGGREGATE_VEHICLE_GROUPS = createType("ALLOWED_AGGREGATE_VEHICLE_GROUPS", 1)
export const AVAILABLE_AGGREGATE_VEHICLE_GROUPS = createType("AVAILABLE_AGGREGATE_VEHICLE_GROUPS", 1)
export const EXISTING_RES_ESTIMATE = createType("EXISTING_RES_ESTIMATE", 1)
export const ALLOW_ONE_WAY = createType("ALLOW_ONE_WAY", 1)
export const ALLOW_PICKUP_AND_DROPOFF = createType("ALLOW_PICKUP_AND_DROPOFF", 1)
export const RESERVATION_ANALYTICS = createType("RESERVATION_ANALYTICS", 1)

// Notification
export const SHOW_MESSAGE = "SHOW_MESSAGE"
export const REMOVE_MESSAGE = "REMOVE_MESSAGE"
export const FADE_MESSAGE = "FADE_MESSAGE"
export const CLEAR_MESSAGES = "CLEAR_MESSAGES"
export const SHOW_TOAST = "SHOW_TOAST"

// Check credentials
export const CHECK_CREDS = "CHECK_CREDS"
// Login
export const LOGIN_REQUEST = "LOGIN_REQUEST"
export const LOGIN_REQUEST_SUCCESS = "LOGIN_REQUEST_SUCCESS"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"
export const LOGIN_FAILURE = "LOGIN_FAILURE"
export const LOGIN_REQUEST_FAILURE = "LOGIN_REQUEST_FAILURE"
export const LOGIN_REQUIRED = "LOGIN_REQUIRED"
export const SSO_LOGIN = "SSO_LOGIN"
// Log out
export const LOGOUT_REQUEST = "LOGOUT_REQUEST"
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS"
export const LOGOUT_FAILURE = "LOGOUT_FAILURE"

// Reservation(s) - CRUD Requests
export const GET_RESERVATIONS_REQUEST = "GET_RESERVATIONS_REQUEST"
export const GET_RESERVATION_REQUEST = "GET_RESERVATION_REQUEST"
export const SET_RESERVATION_REQUEST = "SET_RESERVATION_REQUEST"
export const MODIFY_RESERVATION_REQUEST = "MODIFY_RESERVATION_REQUEST"
export const CLEAR_RESERVATIONS_REQUEST = "CLEAR_RESERVATIONS_REQUEST"
export const CLEAR_RESERVATION_REQUEST = "CLEAR_RESERVATION_REQUEST"
export const CLEAR_MODIFY_RESERVATION_SUCCESS = "CLEAR_MODIFY_RESERVATION_SUCCESS"

export const GET_RATESAVAILABILITY_REQUEST = "GET_RATESAVAILABILITY_REQUEST"
export const GET_OPTIONS_REQUEST = "GET_OPTIONS_REQUEST"

// Reservation(s) - Responses
export const GET_RESERVATIONS_REQUEST_SUCCESS = "GET_RESERVATIONS_REQUEST_SUCCESS"
export const GET_RESERVATIONS_REQUEST_FAILURE = "GET_RESERVATIONS_REQUEST_FAILURE"
export const GET_RESERVATION_REQUEST_SUCCESS = "GET_RESERVATION_REQUEST_SUCCESS"
export const GET_RESERVATION_REQUEST_FAILURE = "GET_RESERVATION_REQUEST_FAILURE"
export const SET_RESERVATION_REQUEST_SUCCESS = "SET_RESERVATION_REQUEST_SUCCESS"
export const SET_RESERVATION_REQUEST_FAILURE = "SET_RESERVATION_REQUEST_FAILURE"
export const MODIFY_RESERVATION_REQUEST_SUCCESS = "MODIFY_RESERVATION_REQUEST_SUCCESS"
export const MODIFY_RESERVATION_REQUEST_FAILURE = "MODIFY_RESERVATION_REQUEST_FAILURE"
export const GET_RATESAVAILABILITY_REQUEST_SUCCESS = "GET_RATESAVAILABILITY_REQUEST_SUCCESS"
export const GET_RATESAVAILABILITY_REQUEST_FAILURE = "GET_RATESAVAILABILITY_REQUEST_FAILURE"
export const GET_OPTIONS_REQUEST_SUCCESS = "GET_OPTIONS_REQUEST_SUCCESS"
export const GET_OPTIONS_REQUEST_FAILURE = "GET_OPTIONS_REQUEST_FAILURE"

// Rental(s) - CRUD Requests
export const GET_RENTALS_REQUEST = "GET_RENTALS_REQUEST"
export const GET_RENTAL_REQUEST = "GET_RENTAL_REQUEST"

// Rental(s) - Responses
export const GET_RENTALS_REQUEST_SUCCESS = "GET_RENTALS_REQUEST_SUCCESS"
export const GET_RENTALS_REQUEST_FAILURE = "GET_RENTALS_REQUEST_FAILURE"
export const GET_RENTAL_REQUEST_SUCCESS = "GET_RENTAL_REQUEST_SUCCESS"
export const GET_RENTAL_REQUEST_FAILURE = "GET_RENTAL_REQUEST_FAILURE"

// Vehicle Model - CRUD Requests
export const GET_VEHICLE_MODEL_REQUEST = "GET_VEHICLE_MODEL_REQUEST"

// Vehicle Model - Responses
export const GET_VEHICLE_MODEL_REQUEST_SUCCESS = "GET_VEHICLE_MODEL_REQUEST_SUCESS"
export const GET_VEHICLE_MODEL_REQUEST_FAILURE = "GET_VEHICLE_MODEL_REQUEST_FAILURE"
export const SET_QUOTATION = "SET_QUOTATION"
export const CLEAR_QUOTATION = "CLEAR_QUOTATION"
export const DOWNLOAD_CONFIRMATION_EMAIL = "DOWNLOAD_CONFIRMATION_EMAIL"
export const DOWNLOAD_CONFIRMATION_EMAIL_SUCESS = "DOWNLOAD_CONFIRMATION_EMAIL_SUCESS"
export const DOWNLOAD_CONFIRMATION_EMAIL_FAILURE = "DOWNLOAD_CONFIRMATION_EMAIL_FAILURE"
