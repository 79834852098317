import React from "react"
import { useSelector, useDispatch } from "react-redux"
import { makeStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import List from "@material-ui/core/List"
import Card from "@material-ui/core/Card"
import CardHeader from "@material-ui/core/CardHeader"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import Checkbox from "@material-ui/core/Checkbox"
import Button from "@material-ui/core/Button"
import Divider from "@material-ui/core/Divider"
import * as userInfoActions from "../../actions/userInfoActions"
import _ from "lodash"
import { Typography } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  root: {
    margin: "auto"
  },
  cardHeader: {
    padding: theme.spacing(1, 2)
  },
  list: {
    width: 300,
    height: 650,
    backgroundColor: theme.palette.background.paper,
    overflow: "auto"
  },
  button: {
    margin: theme.spacing(0.5, 0)
  }
}))

function not(a, b) {
  return a.filter(value => b.indexOf(value) === -1)
}

function intersection(a, b) {
  return a.filter(value => b.indexOf(value) !== -1)
}

function union(a, b) {
  return [...a, ...not(b, a)]
}

export default function TransferList() {
  const locations = useSelector(state =>
    _.get(state, "userInfo.settings.locations", [])
  )
  
  const disallowed = useSelector(state =>
    state.userInfo.settings.meta.find(e => e.key === "disallowedLocations").value
  )
  const disallowedAdmin = useSelector(state =>
    state
    .userInfo
    .settings
    .meta
    .find(e => e.key === "disallowedAdminLocations")
    .value
    .map(e => e.code)
  )

  const locationCodes = _.orderBy(locations.map(e => e.code))
  const disallowedCodes = _.orderBy(disallowed.map(e=>e.code))
  const [checked, setChecked] = React.useState([])
  const auth = useSelector(state => ({ ...state.auth }))
  const classes = useStyles()
  const dispatch = useDispatch()
  const settings = useSelector(state => state.userInfo.settings)
  const [left, setLeft] = React.useState(locationCodes)
  const [right, setRight] = React.useState(disallowedCodes)
  const leftChecked = intersection(checked, left)
  const rightChecked = intersection(checked, right)

  const handleToggle = value => () => {
    const currentIndex = checked.indexOf(value)
    const newChecked = [...checked]

    if (currentIndex === -1) {
      newChecked.push(value)
    } else {
      newChecked.splice(currentIndex, 1)
    }

    setChecked(newChecked)
  }

  const numberOfChecked = items => intersection(checked, items).length

  const handleToggleAll = items => () => {
    let allowed = items;
    if (numberOfChecked(allowed) === allowed.length) {
      setChecked(not(checked, allowed))
    } else {
      setChecked(union(checked, allowed))
    }
  }

  const handleCheckedRight = () => {
    const changed = {
       disallowedLocations: _.union(_.difference(
        disallowedCodes.concat(leftChecked),
        disallowedAdmin
      ))
    };

    dispatch(
      userInfoActions.setUserSettings(
        { token: auth.token },
        changed,
        settings
      )
    )

    setRight(_.orderBy(right.concat(leftChecked)))
    setLeft(not(left, leftChecked))
    setChecked(not(checked, leftChecked))
  }

  const handleCheckedLeft = () => {
    const changed = {
      disallowedLocations: _.union(_.difference(
        disallowedCodes,
        rightChecked
      ))
    };

    dispatch(
      userInfoActions.setUserSettings(
        { token: auth.token },
        changed,
        settings
      )
    )

    setLeft(_.orderBy(left.concat(rightChecked)))
    setRight(not(right, rightChecked))
    setChecked(not(checked, rightChecked))
  }

  function isBusinessDisabled(locationCode) {
    /* if (user.userType == "ADMIN") {
      return false
    } else if (
      _.intersection(settings.businessRules.disallowedLocations, [locationCode])
        .length > 0
    ) {
      return true
    } else return false */
     return disallowedAdmin.includes(locationCode)
  }

  const customList = (title, items) => (
    <div>
      {settings && (
        <Card>
          <CardHeader
            className={classes.cardHeader}
            avatar={
              <Checkbox
                onClick={handleToggleAll(items)}
                checked={
                  numberOfChecked(items) === items.length && items.length !== 0
                }
                indeterminate={
                  numberOfChecked(items) !== items.length &&
                  numberOfChecked(items) !== 0
                }
                disabled={items.length === 0}
                inputProps={{ "aria-label": "all items selected" }}
              />
            }
            title={title}
            subheader={`${numberOfChecked(items)}/${items.length} selected`}
          />
          <Divider />
          <List className={classes.list} dense component="div" role="list">
            {items.map(value => {
              const labelId = `transfer-list-all-item-${value}-label`
              return (
                <ListItem
                  key={value}
                  role="listitem"
                  button
                  onClick={handleToggle(value)}
                  disabled={isBusinessDisabled(value)}
                >
                  <ListItemIcon>
                    <Checkbox
                      checked={checked.indexOf(value) !== -1}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ "aria-labelledby": labelId }}
                    />
                  </ListItemIcon>
                  {/* <ListItemText id={labelId} primary={`List item ${value + 1}`} /> */}
                  <ListItemText id={labelId} primary={`${value}`} />
                </ListItem>
              )
            })}
            <ListItem />
          </List>
        </Card>
      )}
    </div>
  )

  return (
    <div>
      <h2 style={{ paddingBottom: 0, marginBottom: 0, marginLeft: 5 }}>
        Locations
      </h2>
      <Typography
        style={{ color: "grey", fontSize: 13, marginBottom: 5, marginLeft: 5 }}
      >
        This page is used to control disallowed locations site wide. <br />
        Any location that is disallowed will not appear in location searches for
        users.
      </Typography>
      {settings && (
        <Grid
          container
          spacing={2}
          justify="flex-start"
          alignItems="center"
          className={classes.root}
        >
          <Grid item>{customList("Available", left)}</Grid>
          <Grid item>
            <Grid container direction="column" alignItems="center">
              <Button
                variant="outlined"
                size="small"
                className={classes.button}
                onClick={handleCheckedRight}
                disabled={leftChecked.length === 0}
                aria-label="move selected right"
              >
                &gt;
              </Button>
              <Button
                variant="outlined"
                size="small"
                className={classes.button}
                onClick={handleCheckedLeft}
                disabled={rightChecked.length === 0}
                aria-label="move selected left"
              >
                &lt;
              </Button>
            </Grid>
          </Grid>
          <Grid item>{customList("Disallowed", right)}</Grid>
        </Grid>
      )}
    </div>
  )
}
