import * as types from "../constants/actionTypes"
import initialState from "./initialState"

export default function vehicleGroupReducer(state = initialState.userGroups, action) {
    switch (action.type) {
        case types.USER_GROUPS.GET.REQUEST:
            return {
                ...state,
                isFetching: true,
            }
        case types.USER_GROUPS.GET.SUCCESS:
            return {
                ...state,
                data: action.result.data.GetUserGroups,
                isFetching: false,
            }
        case types.USER_GROUPS.GET.FAILURE:
            return {
                ...state,
                data: null,
                isFetching: false,
            }
        case types.USER_GROUPS.DELETE.REQUEST:
            return {
                ...state,
                isFetching: true,
            }
        case types.USER_GROUPS.DELETE.SUCCESS:
            return {
                ...state,
                data: state.data.filter(e => e._id !== action.result._id),
                isFetching: false,
            }
        case types.USER_GROUPS.DELETE.FAILURE:
            return {
                ...state,
                isFetching: false,
            }
        case types.USER_GROUPS.CLEAR:
            return {
                ...state,
                data: null,
                isFetching: false,
            }
        case types.USER_GROUPS.CREATE.REQUEST:
            return {
                ...state,
                isFetching: true,
            }
        case types.USER_GROUPS.CREATE.SUCCESS:
            return {
                ...state,
                data: state.data.concat(action.result.data.CreateUserGroup),
                isFetching: false,
            }
        case types.USER_GROUPS.CREATE.FAILURE:
            return {
                ...state,
                isFetching: false,
            }
        case types.USER_GROUPS.SET.REQUEST:
            return {
                ...state,
                isFetching: true,
            }
        case types.USER_GROUPS.SET.SUCCESS:
            return {
                ...state,
                data: state.data.map(e => e._id === action.result.data.UpdateUserGroup._id 
                    ? action.result.data.UpdateUserGroup : e),
                isFetching: false,
            }
        case types.USER_GROUPS.SET.FAILURE:
            return {
                ...state,
                data: [],
                isFetching: false,
            }
        default:
            return state
    }
}
