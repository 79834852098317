import * as userInfoActions from "../../actions/userInfoActions"
import MaterialTable from "material-table"
import React, { useEffect } from "react"
import tableIcons from "../../utils/helper"
import { useSelector, useDispatch } from "react-redux"

const ItCodes = () => {
  const _ = require("lodash")
  const dispatch = useDispatch()
  const userType = useSelector((state) => _.get(state, "userInfo.settings.user.userType", ""))
  const auth = useSelector((state) => ({ ...state.auth }))
  const businessItCodes = useSelector((state) => _.get(state, "userInfo.settings.itcodes", []))
  const isFetching = useSelector((state) => _.get(state, "userInfo.isFetching", false))
  const itCodes = useSelector((state) => _.get(state, "userInfo.settings.itcodes", []))

  useEffect(() => {
    dispatch(userInfoActions.getServiceSettings({ token: auth.token }))
  }, [])

  return (
    <MaterialTable
      icons={tableIcons}
      isLoading={isFetching}
      title="IT Codes"
      columns={
        userType == "ADMIN"
          ? [
              { title: "IT Code", field: "itCode" },
              { title: "Description", field: "name" },
              { title: "Rider", field: "riderName" },
            ]
          : [
              { title: "IT Code", field: "itCode", editable: "never" },
              { title: "Description", field: "name" },
            ]
      }
      data={
        businessItCodes && itCodes
          ? userType == "ADMIN"
            ? businessItCodes.map((x) => Object.assign({}, x))
            : itCodes.map((x) => Object.assign({}, x))
          : []
      }
      options={{
        minBodyHeight: "calc(100vh - 224px)",
        maxBodyHeight: "calc(100vh - 224px)",
        pageSize: 10,
      }}
      editable={{
        isDeletable: () => userType == "ADMIN",
        isEditable: () => userType == "ADMIN" || userType == "SERVICE_MANAGER",
        onRowAdd:
          userType == "ADMIN"
            ? (newData) =>
                new Promise((resolve) => {
                  setTimeout(() => {
                    {
                      if (userType == "ADMIN") {
                        const changed = _.cloneDeep(businessItCodes)
                        newData.itCode = newData.itCode.toUpperCase()
                        changed.push(newData)
                        dispatch(userInfoActions.setItCodes({ token: auth.token }, changed, userType))
                      }
                    }
                    resolve()
                  }, 1000)
                })
            : undefined,
        onRowUpdate: (newData, target) =>
          new Promise((resolve) => {
            setTimeout(() => {
              {
                let changed = _.cloneDeep(businessItCodes)
                let index = target.tableData.id

                changed[index] = { itCode: newData.itCode, name: newData.name, riderName: newData.riderName }

                dispatch(userInfoActions.setItCodes({ token: auth.token }, changed, userType))
              }
              resolve()
            }, 1000)
          }),
        onRowDelete: (oldData) =>
          new Promise((resolve) => {
            setTimeout(() => {
              {
                let changed = itCodes.filter(function (el) {
                  return el.itCode != oldData.itCode
                })

                dispatch(userInfoActions.setItCodes({ token: auth.token }, changed, userType))
              }
              resolve()
            }, 1000)
          }),
      }}
    />
  )
}

export default ItCodes
