import React from "react"
import { useSelector } from "react-redux"
import { XYPlot, LineSeries, VerticalGridLines, HorizontalGridLines, XAxis, YAxis } from "react-vis"
// import "../../styles/react-vis.css"

const ReactVis = () => {
  const analytics = useSelector((state) => ({ ...state.analytics }))

  let data = [
    { x: 0, y: 0 },
    { x: 1, y: 0 },
    { x: 2, y: 0 },
    { x: 3, y: 0 },
    { x: 4, y: 0 },
    { x: 5, y: 0 },
    { x: 6, y: 0 },
    { x: 7, y: 0 },
  ]

  if (analytics.reservationsCreated) {
    analytics.reservationsCreated.map((value, index) => (data[index].y = value.value))
  }

  return (
    <div className="App">
      <XYPlot height={300} width={850} color="red">
        <XAxis
          tickValues={[0, 1, 2, 3, 4, 5, 6, 7]}
          title="Date"
          tickFormat={function tickFormat(t) {
            const days = analytics.reservationsCreated.map((value) => value.date)
            return days[t]
          }}
        />
        <YAxis title="Reservations" />
        <VerticalGridLines />
        <HorizontalGridLines />
        <LineSeries data={data} />
      </XYPlot>
    </div>
  )
}

export default ReactVis
