import { takeLatest, put, call } from "redux-saga/effects"
import callApi, { deleteCallApi } from "./helpers"
import * as types from "../constants/actionTypes"
import * as appActions from "../actions/appActions"
import url from "url"
import { API_SERVER_URL } from "../config"

export function* getUsers(action) {
    const queryName = action.payload.user.userType === "SERVICE_MANAGERS" ? "GetServiceManagers" : "GetServiceUsers" 
    const config = {
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
            Authorization: action.payload.token != "" ? `Bearer ${action.payload.token}` : null,
        },
        method: "POST",
        body: JSON.stringify({
            query: `query {
                ${queryName} {
                    _id
                    username
                    emailAddress
                    staffId
                    firstName
                    lastName
                    lastLogin
                    userType
                    favouriteLocations
                    favouriteItcodes
                    meta {
                        key
                        value
                    }
                    accountUserGroups {
                        _id
                        name
                    }
                }
            }
            `
        }),
    }

    const uri = url.resolve(API_SERVER_URL, "")

    try {
        yield call(callApi, () => fetch(uri, config), [types.USERS.GET.SUCCESS, types.USERS.GET.FAILURE])
    } catch (error) {
        yield put(appActions.showMessage("Request Error", error.message, "danger"))
    }
}

export function* createUser(actions) {
    const userGroupString = JSON.stringify(actions.payload.user.userGroups);

    const config = {
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
            Authorization: actions.payload.token != "" ? `Bearer ${actions.payload.token}` : null,
        },
        method: "POST",
        body: JSON.stringify({
            query: `mutation CreateUser {
                CreateUser(emailAddress: "${actions.payload.user.emailAddress}", userGroups: ${userGroupString}) {
                    _id
                    username
                    emailAddress
                    staffId
                    firstName
                    lastName
                    lastLogin
                    userType
                    favouriteLocations
                    favouriteItcodes
                }
            }
            `
        }),
    }

    const uri = url.resolve(API_SERVER_URL, "")

    try {
        yield call(callApi, () => fetch(uri, config), [types.USERS.CREATE.SUCCESS, types.USERS.CREATE.FAILURE])
    } catch (error) {
        yield put(appActions.showMessage("Request Error", error.message, "danger"))
    }
}

export function* promoteUser(actions) {
    const config = {
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
            Authorization: actions.payload.token != "" ? `Bearer ${actions.payload.token}` : null,
        },
        method: "POST",
        body: JSON.stringify({
            query: `mutation PromoteUser {
                        PromoteUser(_id: "${actions.payload.user._id}") {
                            _id
                            username
                            emailAddress
                            staffId
                            firstName
                            lastName
                            lastLogin
                            userType
                            favouriteLocations
                            favouriteItcodes
                        }
                    }`
        }),
    }

    const uri = url.resolve(API_SERVER_URL, "")

    try {
        yield call(callApi, () => fetch(uri, config), [types.USERS.PROMOTE.SUCCESS, types.USERS.PROMOTE.FAILURE])
    } catch (error) {
        yield put(appActions.showMessage("Request Error", error.message, "danger"))
    }
}

export function* setUser(actions) {
    const favouriteLocations = JSON.stringify(actions.payload.user.favouriteLocations);
    const favouriteItcodes = JSON.stringify(actions.payload.user.favouriteItcodes);
    const accountUserGroups = JSON.stringify(actions.payload.user.accountUserGroups.reduce((acc, e) => {
        if(Object.hasOwn(e, "_id") && e._id === undefined || e._id === null) {
            return acc;
        }
        return acc.concat(e._id)
    }, []));
    const config = {
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
            Authorization: actions.payload.token != "" ? `Bearer ${actions.payload.token}` : null,
        },
        method: "POST",
        body: JSON.stringify({
            query: `mutation UpdateUser {
                UpdateUser(
                    _id: "${actions.payload.user._id}"
                    username: "${actions.payload.user.username}"
                    emailAddress: "${actions.payload.user.emailAddress}"
                    firstName: "${actions.payload.user.firstName}"
                    lastName: "${actions.payload.user.lastName}"
                    favouriteLocations: ${favouriteLocations}
                    favouriteItcodes: ${favouriteItcodes}
                    accountUserGroups: ${accountUserGroups}
                ) {
                    _id
                    username
                    emailAddress
                    staffId
                    firstName
                    lastName
                    lastLogin
                    userType
                    favouriteLocations
                    favouriteItcodes
                    accountUserGroups {
                        _id
                        name
                        active
                    }
                    meta {
                        key
                        value
                    }
                }
            }`,
        }),
    }

    const uri = url.resolve(API_SERVER_URL, "")

    try {
        yield call(callApi, () => fetch(uri, config), [types.USERS.SET.SUCCESS, types.USERS.SET.FAILURE])
    } catch (error) {
        yield put(appActions.showMessage("Request Error", error.message, "danger"))
    }
}

export function* requireResetPassword(actions) {
    const config = {
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
            Authorization: actions.payload.token != "" ? `Bearer ${actions.payload.token}` : null,
        },
        method: "POST",
        body: JSON.stringify({
            query: `mutation RequireResetPassword {
                RequireResetPassword(_id: "${actions.payload.user._id}") {
                    _id
                    username
                    emailAddress
                    staffId
                    firstName
                    lastName
                    lastLogin
                    userType
                    favouriteLocations
                    favouriteItcodes
                    meta {
                        key
                        value
                    }
                    accountUserGroups {
                        _id
                        name
                        active
                    }
                }
            }`,
        }),
    }

    const uri = url.resolve(API_SERVER_URL, "")

    try {
        yield call(callApi, () => fetch(uri, config), [
            types.USERS.REQUIRE_RESET_PASSWORD.SUCCESS, 
            types.USERS.REQUIRE_RESET_PASSWORD.FAILURE
        ])
    } catch (error) {
        yield put(appActions.showMessage("Request Error", error.message, "danger"))
    }
}

export function* deleteUser(actions) {
    
    
    const config = {
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
            Authorization: actions.payload.token != "" ? `Bearer ${actions.payload.token}` : null,
        },
        method: "POST",
        body: JSON.stringify({
            query: `mutation DeleteVehicleGroups {
                        DeleteVehicleGroups(GroupIDs: ["${actions.payload.vehicleGroup._id}"]) {
                            message
                        }
                    }   
                    `,
        }),
    }

    const uri = url.resolve(API_SERVER_URL, "")

    try {
        yield call(
            deleteCallApi,
            () => fetch(uri, config),
            [types.USERS.DELETE.SUCCESS, types.USERS.DELETE.FAILURE],
            "json",
            {params: actions.payload.vehicleGroup._id}
        )
    } catch (error) {
        yield put(appActions.showMessage("Request Error", error.message, "danger"))
    }
}

export function* changePassword(actions) {  
    
    const config = {
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
            Authorization: actions.payload.token != "" ? `Bearer ${actions.payload.token}` : null,
        },
        method: "POST",
        body: JSON.stringify({
            query: `mutation ChangePassword {
                        ChangePassword(
                            oldPassword: "${actions.payload.user.oldPassword}",
                            newPassword: "${actions.payload.user.newPassword}",
                            cpassword: "${actions.payload.user.cPassword}"
                        ) {
                            username
                        }
                    }`,
        }),
    }

    const uri = url.resolve(API_SERVER_URL, "")

    try {
        yield call(callApi, () => fetch(uri, config), [types.USERS.CHANGE_PASSWORD.SUCCESS, types.USERS.CHANGE_PASSWORD.FAILURE])
    } catch (error) {
        yield put(appActions.showMessage("Request Error", error.message, "danger"))
    }
}

export function* watchUsersRequest() {
    yield takeLatest(types.USERS.GET.REQUEST, getUsers)
    yield takeLatest(types.USERS.SET.REQUEST, setUser)
    yield takeLatest(types.USERS.CREATE.REQUEST, createUser)
    yield takeLatest(types.USERS.DELETE.REQUEST, deleteUser)
    yield takeLatest(types.USERS.PROMOTE.REQUEST, promoteUser)
    yield takeLatest(types.USERS.CHANGE_PASSWORD.REQUEST, changePassword)
    yield takeLatest(types.USERS.REQUIRE_RESET_PASSWORD.REQUEST, requireResetPassword)
}
