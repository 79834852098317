import {acrissCategories, acrissTypes, acrissTransmision, acrissFuelAndAir} from '../constants/ACRISS'

const _ = require('lodash');


export function capitaliseWord(ReservationStatus) {
    ReservationStatus = ReservationStatus.slice(0,1).toUpperCase() + ReservationStatus.slice(1, ReservationStatus.length)

    return ReservationStatus
}

export function acrissDecoder(acrissCode) {
    if(acrissCode){
        let acrissArray = [...acrissCode]
        let acrissObject = {
            Category: _.filter(acrissCategories, {Code: acrissArray[0]})[0],
            Type: _.filter(acrissTypes, {Code: acrissArray[1]})[0],
            Transmission: _.filter(acrissTransmision, {Code: acrissArray[2]})[0],
            FuelAndAir: _.filter(acrissFuelAndAir, {Code: acrissArray[3]})[0]
        }

        return acrissObject
    }else{
        return null
    }
}