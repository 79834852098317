import React, { useState, useEffect, useContext } from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Container, Typography, Card, CardContent, Grid, Box, CircularProgress } from "@material-ui/core"

import MobileStepper from "@material-ui/core/MobileStepper"
import Button from "@material-ui/core/Button"
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft"
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight"

import ClearIcon from "@material-ui/icons/Clear"
import DoneIcon from "@material-ui/icons/Done"
import CreateIcon from "@material-ui/icons/Create"
import MailOutlineIcon from "@material-ui/icons/MailOutline"

import ReactCardFlip from "react-card-flip"
import { useDispatch, useSelector } from "react-redux"

import GoogleMapBox from "../partials/GoogleMapBox"
import Quotation from "../partials/Quotation"
import BookingDetails from "../partials/BookingDetails"
import BookingConfirmation from "../partials/BookingConfirmation"
import LocationSearch from "./LocationSearch"
import BookingSummary from "../partials/BookingSummary"

import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"

import * as bookingsActions from "../../actions/bookingsActions"
import * as appActions from "../../actions/appActions"
import moment from "moment"

import { i18n } from "../i18n"
import _ from "lodash"

// eslint-disable-next-line no-unused-vars
function a11yProps(index) {
  return {
    id: `tab-${index}`,
  }
}

function TabPanel(props) {
  // eslint-disable-next-line react/prop-types
  const { children, value, index, ...other } = props

  return (
    <Typography component="div" role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`} {...other}>
      <Box p={0}>{children}</Box>
    </Typography>
  )
}

// eslint-disable-next-line no-unused-vars
const CreateBookingTab = (props) => {
  const classes = useStyles()
  const auth = useSelector((state) => state.auth)
  const userInfo = useSelector((state) => state.userInfo)
  const locations = useSelector((state) => state.locations?.locations)
  const quotation = useSelector((state) => state.quotation)
  const bookings = useSelector((state) => state.bookings)
  const [createBookingTab, setCreateBookingTab] = useState(0)
  const [showSearchLocation, setShowSearchLocation] = useState(false)
  const [showFavoriteLocations, setShowFavoriteLocations] = useState(true)
  const [dialog, setDialog] = React.useState(false)
  const dispatch = useDispatch()

  const [activeStep, setActiveStep] = React.useState(0)

  const [highlightedLocation, setHighlightedLocation] = useState(null)
  const [viewingLocation, setViewingLocation] = useState(null)
  const [excludeAptLocations, setExcludeAptLocations] = useState(false)
  const [clickedNext, setClickedNext] = useState(false)


  const labelContext = useContext(i18n)
  const getLabel = (label, defaultValue) => {
    if (i18n !== undefined) {
      return _.get(labelContext, `labels.${label}`, defaultValue)
    } else {
      return defaultValue
    }
  }

  useEffect(() => {
    dispatch(
      bookingsActions.setBookings({
        isCreatingReservation: false,
        isDownloadingEmailTemplate: false,
      })
    )
  }, [])

  const handleOnDownloadEmail = () => {
    dispatch(
      bookingsActions.downloadConfirmationEmail({
        type: "createReservation",
        mode: "reservation",
        token: auth.token,
        reservationNumber: bookings.reservationNumber,
        rentalNumber: "",
        userInfo: { ...userInfo },
        locations: [...locations],
      })
    )
  }

  const validateFields = () => {
    switch (activeStep.toString()) {
      case "1": {
        const good =
          quotation.customer.firstname != "" &&
          quotation.customer.lastname != "" &&
          quotation.customer.email != "" &&
          quotation.customer.phone != "" &&
          quotation.customer.mobile != "" &&
          quotation.customer.address != "" &&
          quotation.customer.city != "" &&
          quotation.customer.country != "" &&
          // quotation.customer.postcode != "" &&
          moment(quotation.customer.expiry).format("YYYY-MM-DD") > moment().format("YYYY-MM-DD") &&
          moment(quotation.customer.expiry).isValid() &&
          quotation.customer.license != "" &&
          quotation.customer.issuer != "" &&
          quotation.customer.expiry != "" &&
          quotation.claimnumber != "" &&
          quotation.policynumber != ""
        if (!good) {
          //dispatch error notification
          dispatch(appActions.showMessage("Can not proceed to next step", "Please check required fields", "warning"))
        }
        if (!moment(quotation.customer.expiry).isValid() || moment(quotation.customer.expiry).format("YYYY-MM-DD") <= moment().format("YYYY-MM-DD")) {
          dispatch(appActions.showMessage("Error", "Invalid Expiry Date", "warning"))

        }
        return good
      }
      default: {
        return true
      }
    }
  }

  const handleNext = () => {
    setClickedNext(activeStep == 1)
    if (validateFields()) {
      setShowSearchLocation(false)
      setCreateBookingTab(activeStep + 1)
      setActiveStep((prevActiveStep) => prevActiveStep + 1)
    }
  }

  const handleBack = () => {
    if (activeStep == 0 && showSearchLocation) {
      // dispatch(push({ pathname: "reservations" }))
      setShowSearchLocation(false)
      return
    }
    setShowSearchLocation(false)
    setCreateBookingTab(activeStep - 1)
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  // eslint-disable-next-line no-unused-vars
  function handleCreateBookingTabChange(e, newValue) {
    e.preventDefault()
    setShowSearchLocation(false)
    setCreateBookingTab(newValue)
  }

  const handleOnSearchLocation = (type) => {
    setShowSearchLocation(true)
    if (setShowSearchLocation == true) {
      setShowSearchLocation(false)
      dispatch(
        bookingsActions.setQuotation({
          pickUpSearch: false,
          dropOffSearch: false,
        })
      )
    } else {
      type == "pickUp"
        ? dispatch(
          bookingsActions.setQuotation({
            pickUpSearch: true,
            dropOffSearch: false,
          })
        )
        : dispatch(
          bookingsActions.setQuotation({
            pickUpSearch: false,
            dropOffSearch: true,
          })
        )
      setShowSearchLocation(true)
    }
  }

  // eslint-disable-next-line no-unused-vars
  const handleOnCompleteCreateBooking = (e) => {
    dispatch(
      bookingsActions.setReservation({
        ...quotation,
        token: auth.token,
        staffid: userInfo.settings.user.staffid,
        referenceId: bookings.referenceId,
      })
    )
  }

  const handleOnCompleteEditBooking = () => {
    dispatch(
      bookingsActions.modifyReservation({
        ...quotation,
        token: auth.token,
        staffid: userInfo.settings.user.staffid,
        reservationNumber: bookings.reservationNumber,
      })
    )
  }

  // eslint-disable-next-line no-unused-vars
  const handleOnClearQuotation = (e) => {
    dispatch(bookingsActions.clearQuotation({}))
    setDialog(false)
    setActiveStep(0)
    setCreateBookingTab(0)

    // todo: can be removed to save api calls but have to make sure these are not cleared.
    // eslint-disable-next-line import/namespace
    // dispatch(userInfoActions.getUserSettings({ token: auth.token }))
    dispatch(bookingsActions.getVehicleModels({ token: auth.token }))
    // dispatch(locationsActions.getAllLocations({ token: auth.token }))
  }

  const handleOpen = () => {
    setDialog(true)
  }

  const handleClose = () => {
    setDialog(false)
  }

  return (
    <div className={classes.root}>
      <Container className={classes.mainpane}>
        <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>
          <Grid item xs={6} lg={7}>
            <Card
              style={{
                height: "calc(100vh - 164px)",
                margin: "auto",
                overflowY: "auto",
              }}
            >
              <LocationSearch
                viewingLocation={viewingLocation}
                setViewingLocation={setViewingLocation}
                setHighlightedLocation={setHighlightedLocation}
                highlightedLocation={highlightedLocation}
                showSearchLocation={showSearchLocation}
                setShowSearchLocation={setShowSearchLocation}
                setShowFavoriteLocations={setShowFavoriteLocations}
                excludeAptLocations={excludeAptLocations}
                setExcludeAptLocations={setExcludeAptLocations}
              />
              <CardContent style={{ display: showSearchLocation ? "none" : "block" }}>
                <TabPanel value={createBookingTab} index={0}>
                  <Typography variant="h6">{getLabel("quotation.header", "Build A Quote")}</Typography>
                  <br />
                  <Quotation onLocationSearch={handleOnSearchLocation} />
                </TabPanel>
                <TabPanel value={createBookingTab} index={1}>
                  <Typography variant="h6">{getLabel("customerdetails.header", "Customer Details")}</Typography>
                  <br />
                  <BookingDetails clickedNext={clickedNext} />
                </TabPanel>
                <TabPanel value={createBookingTab} index={2}>
                  <BookingConfirmation />
                </TabPanel>
              </CardContent>
            </Card>
            <Card style={{ marginTop: "10px" }}>
              <MobileStepper
                variant="dots"
                steps={3}
                position="static"
                activeStep={activeStep}
                nextButton={
                  <Button size="small" onClick={handleNext} disabled={activeStep === 2 || bookings.estimate == null}>
                    Next
                    <KeyboardArrowRight />
                  </Button>
                }
                backButton={
                  <Button
                    size="small"
                    onClick={handleBack}
                    disabled={
                      (activeStep == 0 && !showSearchLocation) ||
                      (location.pathname.split("/")[4] != "editreservation" && bookings.reservationNumber != "")
                    }
                  >
                    <KeyboardArrowLeft />
                    Back
                  </Button>
                }
              />
            </Card>
          </Grid>
          <Grid item xs={6} lg={5}>
            <ReactCardFlip
              isFlipped={showSearchLocation}
              flipDirection="horizontal"
              infinite
              containerStyle={{
                height: "calc(100vh - 164px)",
                overflowY: showSearchLocation ? "hidden" : "auto",
                overflowX: "hidden",
                borderRadius: "4px",
              }}
            >
              <Card key="front" style={{ padding: "0px", backgroundColor: "#fff" }}>
                <CardContent style={{ height: "100%", padding: "0px" }}>
                  <BookingSummary />
                </CardContent>
              </Card>
              <Card key="back" id='card-back' style={{ height: "100%", padding: "0px" }}>
                <CardContent id='card-back2' style={{ height: "100%", padding: "0px" }}>
                  {/* <MapBox setShowSearchLocation={setShowSearchLocation} /> */}
                  <GoogleMapBox
                    viewingLocation={viewingLocation}
                    setViewingLocation={setViewingLocation}
                    highlightedLocation={highlightedLocation}
                    setHighlightedLocation={setHighlightedLocation}
                    showFavoriteLocations={showFavoriteLocations}
                    setShowSearchLocation={setShowSearchLocation}
                    excludeAptLocations={excludeAptLocations}
                    setExcludeAptLocations={setExcludeAptLocations}
                  />
                </CardContent>
              </Card>
            </ReactCardFlip>
            <Card
              style={{
                marginTop: "10px",
                height: "48px",
                lineHeight: "48px",
                padding: "0 10px",
              }}
            >
              <Grid container>
                <Grid item xs={6}>
                  {location.pathname.split("/")[4] != "editreservation" && (
                    <Button size="small" onClick={handleOpen}>
                      {bookings.reservationNumber != "" ? (
                        <React.Fragment>
                          <CreateIcon style={{ marginRight: "10px", fontSize: "20px" }} />
                          New Quotation
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <ClearIcon style={{ marginRight: "10px", fontSize: "20px" }} />
                          Clear Quotation
                        </React.Fragment>
                      )}
                    </Button>
                  )}
                </Grid>
                <Grid item xs={6} style={{ textAlign: "right" }}>
                  {location.pathname.split("/")[4] == "editreservation" ? (
                    <Button
                      size="small"
                      variant="contained"
                      color="secondary"
                      disabled={activeStep < 2 || bookings.isEditingReservation}
                      onClick={handleOnCompleteEditBooking}
                    >
                      Complete Edit Booking
                      {bookings.isEditingReservation ? (
                        <CircularProgress size={15} style={{ marginLeft: "10px" }} />
                      ) : (
                        <DoneIcon style={{ marginLeft: "10px", fontSize: "20px" }} />
                      )}
                    </Button>
                  ) : bookings.reservationConfirmed ? (
                    <Button onClick={handleOnDownloadEmail}>
                      Download Email Confirmation
                      {bookings.isDownloadingEmailTemplate ? (
                        <CircularProgress size={15} style={{ marginLeft: "10px" }} />
                      ) : (
                        <MailOutlineIcon size={15} style={{ marginLeft: "10px" }} />
                      )}
                    </Button>
                  ) : (
                    (activeStep == 2 || bookings.isCreatingReservation || bookings.reservationNumber != "") && (
                      <Button
                        size="small"
                        variant="contained"
                        color="secondary"
                        onClick={handleOnCompleteCreateBooking}
                      >
                        Complete Create Booking
                        {bookings.isCreatingReservation ? (
                          <CircularProgress size={15} style={{ marginLeft: "10px" }} />
                        ) : (
                          <DoneIcon style={{ marginLeft: "10px", fontSize: "20px" }} />
                        )}
                      </Button>
                    )
                  )}
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </Container>
      <Dialog
        open={dialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Are you sure you want to proceed?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This will clear the quotation and start all over. Please click OK to confirm if you want to proceed or click
            cancel to close this message.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleOnClearQuotation} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    height: "calc(100vh - 64px)",
  },
  mainpane: {
    paddingTop: "20px",
    maxWidth: "100%",
    background: "#efefef",
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}))

export default CreateBookingTab
